import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";
import { TagsContainer } from "../TagsContainer";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState(false);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);

          setContact(data.contact);
          setTicket(data);
          if (ticket.isGroup) {
            setGroup(true);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deleted sucessfully.");
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const renderMessageInput = () => {
 
    if (!ticket || !ticket.whatsapp || !user) {
      return <MessageInput ticketStatus={ticket.status} />
    }
    const isUserAllowed = 
      user.profile.toUpperCase() === "ADMIN" ||
      ticket.isGroup ||
      user.id === ticket.userId;
    if (ticket.whatsapp.showOnlyUserMessages) {
      if (isUserAllowed && ticket.status === "open") {
        return <MessageInput ticketStatus={ticket.status} />;
      } else {
        return <MessageInput ticketStatus={"closed"} />;
      }
    }
    return <MessageInput ticketStatus={ticket.status} />;
  };
  
  return (
    <div className={classes.root} id="drawer-container">
      {user.queues.some(
        (userQueue) => userQueue.UserQueue.queueId === ticket.queueId
      ) || user.profile.toUpperCase() === "ADMIN" ? (
        <Paper
          variant="outlined"
          elevation={0}
          className={clsx(classes.mainWrapper, {
            [classes.mainWrapperShift]: drawerOpen,
          })}
        >
          <TicketHeader loading={loading}>
            <div className={classes.ticketInfo}>
              <TicketInfo
                contact={contact}
                ticket={ticket}
                onClick={handleDrawerOpen}
              />
            </div>
            <div className={classes.ticketActionButtons}>
              <TicketActionButtons ticket={ticket} />
            </div>
          </TicketHeader>
          <Paper>
            <TagsContainer ticket={ticket} />
          </Paper>
          <ReplyMessageProvider>
            <MessagesList
              ticketId={ticketId}
              isGroup={ticket.isGroup}
            ></MessagesList>
            {renderMessageInput()}
          </ReplyMessageProvider>
        </Paper>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            background: "#EEEEEE",
            position: "absolute",
          }}
        >
          Você não tem permissão para acessar essa conversa.
        </div>
      )}

      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
        ticketId={ticketId}
        isGroup={group}
      />
    </div>
  );
};

export default Ticket;
