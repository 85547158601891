import React, { useState, useEffect, useRef } from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import IconButton from "@material-ui/core/IconButton";
import api from "../../services/api";

const WebSoftPhone = () => {
  const dialer = useRef(null);
  const [callState, setCallState] = useState(null);
  const [callerNumber, setCallerNumber] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  useEffect(() => {
    // Inicializa o dialer somente uma vez, se ainda não estiver carregado
    if (!dialer.current && window.Net2PhoneDialer) {
      dialer.current = new window.Net2PhoneDialer({
        rootHtmlElement: document.getElementById("net2phone-dialer"),
      });
    }

    const handleCallStateChanged = (event) => {
      if (event.type === "callStateChanged") {
        const { call } = event;

        handleEvent(event);

        setCallState(call.state);

        if (call.state === "disconnected") {
          setVisible(false);

          const iframe = document.querySelector("#net2phone-dialer iframe");
          if (iframe) {
            iframe.style.backgroundColor = "white";
            iframe.style.boxShadow = "rgba(0, 0, 0, 0.35) 0px 5px 15px";
          }
        }

        if (call.state === "connecting" && call.direction === "inbound") {
          setVisible(true);

          const iframe = document.querySelector("#net2phone-dialer iframe");
          if (iframe) {
            iframe.style.backgroundColor = "transparent";
            iframe.style.boxShadow = "none";
          }
        }
        if (call.state === "connecting" && call.direction === "outbound") {
          const iframe = document.querySelector("#net2phone-dialer iframe");
          if (iframe) {
            iframe.style.backgroundColor = "transparent";
            iframe.style.boxShadow = "none";
          }
        }
      }
    };

    if (dialer.current) {
      const subscription = dialer.current.subscribe(handleCallStateChanged);
      return () => {
        subscription.dispose();
      };
    }

    return undefined;
  }, []);

  const handleEvent = async (event) => {
    try {
      const callState = event.call.state;

      if (callState !== "connecting") {
        const callEventData = event.call;
        const payload = await api.post("/calls", { callEventData });
      }
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
    }
  };

  return (
    <div>
      <IconButton onClick={handleOpen} color="inherit">
        <PhoneIcon />
      </IconButton>
      <div
        id="net2phone-dialer"
        style={{
          display: visible ? "block" : "none",
          position: "absolute",
          left: "20px",
        }}
      ></div>
    </div>
  );
};

export default WebSoftPhone;
